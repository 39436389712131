<template>
  <div id="loginpage" class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-sm-row flex-column-fluid justify-content-md-center my-fixed-col md-col-auto"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      style="width: 100%; height: 100%"
      id="kt_login"
    >
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-5 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <transition name="slide-up" mode="out-in" @after-enter="onAfterEnter">
            <!--begin::Signin-->
            <div
              v-if="state === 'signin'"
              key="signin"
              class="login-form login-signin"
            >
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
                @submit.prevent="submitForm"
              >
                <div class="">
                  <img
                    class="mb-6"
                    src="/media/logos/Main_Logo.png"
                    height="50px;"
                  />
                  <!-- <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                  <img src="/media/logos/logo.png" height="55px;" />&nbsp;&nbsp;Refine My Data<br /><br />
                </h3> -->
                  <div class="loginheader">Login to your account</div>
                  <p class="loginheaderbelow mb-7">
                    Please enter your login details below
                  </p>
                </div>

                <div class="form-group">
                  <label for="email" class="email">Email</label>
                  <div class="form-view">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      tabindex="1"
                      name="email"
                      ref="email"
                      v-model="form.email"
                      placeholder="Enter Email"
                      autofocus
                    />
                    <inline-svg
                      width="15"
                      height="15"
                      src="media/svg/icons/General/mail-icon.svg"
                      class="mailicon"
                    />
                    <!-- <v-icon style="margin-top: -63px;margin-left: 10px;">mdi-email-outline</v-icon> -->
                  </div>
                  <span v-if="signinFormErrors.email" style="color: red">{{
                    signinFormErrors.email
                  }}</span>
                </div>

                <div class="form-group">
                  <!-- <div class="d-flex justify-content-between mt-n5"> -->
                  <label class="">Password </label>
                  <!-- <a
                    class="text-primary font-size-h4 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    style="cursor:pointer"                    
                    @click="showForm('forgot')"
                    >Forgot Password ?</a
                  > -->
                  <!-- </div> -->
                  <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                  >
                    <input
                      class="form-control form-control-solid"
                      :type="switchVisibility(showpassword)"
                      name="password"
                      tabindex="2"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                      placeholder="Enter Password "
                    />
                    <inline-svg
                      width="15"
                      height="15"
                      src="media/svg/icons/General/lock-icon.svg"
                      class="lockicon"
                    />
                    <span class="eyeicon"
                      ><v-icon
                        v-if="showpassword"
                        @click="showpassword = !showpassword"
                        >mdi-eye</v-icon
                      ></span
                    >
                    <span class="eyeicon">
                      <v-icon
                        v-if="!showpassword"
                        @click="showpassword = !showpassword"
                        >mdi-eye-off</v-icon
                      ></span
                    >
                  </div>
                  <span v-if="signinFormErrors.password" style="color: red">{{
                    signinFormErrors.password
                  }}</span>
                </div>

                <div class="d-flex align-items-center justify-content-between">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="autoSizingCheck"
                      v-model="form.remember"
                    />
                    <label class="form-check-label" for="autoSizingCheck">
                      Remember me
                    </label>
                  </div>
                  <div class="">
                    <div>
                      <span class="forgot">Forgot Password?</span
                      ><span>
                        &nbsp;&nbsp;<a
                          class="reset"
                          id="kt_login_forgot"
                          style="cursor: pointer"
                          @click="showForm('forgot')"
                          >Reset Now</a
                        ></span
                      >
                    </div>
                  </div>
                </div>
                <div class="mt-15">
                  <button
                    ref="kt_login_signin_submit"
                    tabindex="3"
                    class="btn btn-primary loginbutton"
                  >
                    Login
                  </button>
                </div>

                <div class="loginFooteOuter text-center">
                  <span class="loginFooter"
                    >Don't have an account
                    <a
                      id="kt_login_signup"
                      class="text-primary"
                      tabindex="4"
                      style="cursor: pointer"
                      @click="showForm('signup')"
                      >Create Account</a
                    ></span
                  >
                </div>
              </form>
            </div>
          </transition>

          <!--end::Signin-->
          <!--begin::Signup-->

          <transition name="slide-up" mode="out-in" @after-enter="onAfterEnter">
            <div
              v-if="state === 'signup'"
              key="signup"
              class="login-form login-signup"
            >
              <form
                class="form"
                method="post"
                action="#"
                novalidate="novalidate"
                id="kt_login_signup_form"
                @submit.prevent="submitSignupForm"
              >
                <div class="pb-5 pt-lg-0 pt-5">
                  <img
                    class="mb-6"
                    src="/media/logos/Main_Logo.png"
                    height="50px;"
                  />
                  <h3 class="loginheader">Create an Account</h3>
                  <span class="loginheaderbelow">
                    Please fill in the details below to create your account.
                  </span>
                </div>

                <div class="row form-group d-flex justify-content-between">
                  <div class="col">
                    <label class="">Forename</label>
                    <input
                      class="form-control form-control-solid"
                      type="text"
                      name="fullname"
                      ref="fullname"
                      v-model="registerform.name"
                      @input="registerform.name = capitalize(registerform.name)"
                      autocomplete="off"
                      tabindex="1"
                      placeholder="Enter Forename"
                      required
                      autofocus
                      style="width: 210px"
                    />
                    <inline-svg
                      width="15"
                      height="15"
                      src="media/svg/icons/General/profile_icon.svg"
                      class="profileicon"
                    />
                    <span v-if="signupFormErrors.name" style="color: red">
                      {{ signupFormErrors.name }}
                    </span>
                  </div>
                  <div class="col">
                    <label class="">Surname</label>
                    <input
                      class="form-control form-control-solid"
                      type="text"
                      name="surname"
                      ref="surname"
                      v-model="registerform.surname"
                      @input="
                        registerform.surname = capitalize(registerform.surname)
                      "
                      autocomplete="off"
                      tabindex="2"
                      placeholder="Enter Surname"
                      required
                      style="width: 210px"
                    />
                    <inline-svg
                      width="15"
                      height="15"
                      src="media/svg/icons/General/profile_icon.svg"
                      class="profileicon"
                    />
                    <span v-if="signupFormErrors.surname" style="color: red">
                      {{ signupFormErrors.surname }}
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <label class="">Your Company Name </label>
                  <input
                    class="form-control form-control-solid"
                    type="text"
                    name="company"
                    ref="company"
                    v-model="registerform.company"
                    @input="
                      registerform.company = capitalize(registerform.company)
                    "
                    autocomplete="off"
                    tabindex="3"
                    placeholder="Enter Company Name"
                    required
                  />
                  <inline-svg
                    width="15"
                    height="15"
                    src="media/svg/icons/General/office-icon.svg"
                    class="profileicon"
                  />
                  <span v-if="signupFormErrors.company" style="color: red">
                    {{ signupFormErrors.company }}
                  </span>
                </div>

                <div class="form-group">
                  <label class="">Email</label>
                  <input
                    class="form-control form-control-solid"
                    type="email"
                    name="email"
                    ref="remail"
                    v-model="registerform.email"
                    autocomplete="off"
                    tabindex="4"
                    placeholder="Enter Email"
                    required
                  />
                  <inline-svg
                    width="15"
                    height="15"
                    src="media/svg/icons/General/mail-icon.svg"
                    class="mailicon"
                  />
                  <span v-if="signupFormErrors.email" style="color: red">
                    {{ signupFormErrors.email }}
                  </span>
                </div>

                <div class="form-group">
                  <label class="">Confirm Email </label>
                  <input
                    class="form-control form-control-solid"
                    type="email"
                    name="cemail"
                    ref="cremail"
                    v-model="registerform.email_confirmation"
                    autocomplete="off"
                    tabindex="5"
                    placeholder="Confirm Email"
                    @change="validatemessage"
                  />
                  <inline-svg
                    width="15"
                    height="15"
                    src="media/svg/icons/General/mail-icon.svg"
                    class="mailicon"
                  />
                  <span
                    v-if="signupFormErrors.email_confirmation"
                    style="color: red"
                  >
                    {{ signupFormErrors.email_confirmation }}
                  </span>

                  <p style="color: red">{{ emailError }}</p>
                </div>

                <div class="form-group">
                  <label class="">Password </label>
                  <input
                    class="form-control form-control-solid"
                    :type="switchVisibility(showregpassword)"
                    name="password"
                    ref="rpassword"
                    v-model="registerform.password"
                    autocomplete="off"
                    tabindex="5"
                    placeholder="Enter Password"
                    required
                  />
                  <inline-svg
                    width="15"
                    height="15"
                    src="media/svg/icons/General/lock-icon.svg"
                    class="lockicon"
                  />
                  <span class="eyeicon">
                    <v-icon
                      small
                      v-if="showregpassword"
                      @click="showregpassword = !showregpassword"
                      >mdi-eye</v-icon
                    >
                  </span>
                  <span class="eyeicon">
                    <v-icon
                      small
                      v-if="!showregpassword"
                      @click="showregpassword = !showregpassword"
                      >mdi-eye-off</v-icon
                    >
                  </span>
                  <span v-if="signupFormErrors.password" style="color: red">
                    {{ signupFormErrors.password }}
                  </span>
                </div>

                <div class="form-group">
                  <label class="">Confirm Password </label>
                  <input
                    class="form-control form-control-solid"
                    :type="switchVisibility(showregconfirmpassword)"
                    name="cpassword"
                    ref="cpassword"
                    v-model="registerform.password_confirmation"
                    autocomplete="off"
                    tabindex="7"
                    placeholder="Enter Password again"
                    required
                  />
                  <inline-svg
                    width="15"
                    height="15"
                    src="media/svg/icons/General/lock-icon.svg"
                    class="lockicon"
                  />
                  <span class="eyeicon">
                    <v-icon
                      small
                      v-if="showregconfirmpassword"
                      @click="showregconfirmpassword = !showregconfirmpassword"
                      >mdi-eye</v-icon
                    >
                  </span>
                  <span class="eyeicon">
                    <v-icon
                      small
                      v-if="!showregconfirmpassword"
                      @click="showregconfirmpassword = !showregconfirmpassword"
                      >mdi-eye-off</v-icon
                    >
                  </span>
                  <span
                    v-if="signupFormErrors.password_confirmation"
                    style="color: red"
                  >
                    {{ signupFormErrors.password_confirmation }}
                  </span>
                </div>

                <div class="form-group">
                  <div class="mb-0">
                    <input type="checkbox" name="agree" required />
                    <span class="mr-2" tabindex="8"></span>
                    I Agree to the
                    <a
                      href="https://www.mediabasedirect.com/terms"
                      target="_blank"
                      class="ml-2 text-success"
                      >terms and conditions</a
                    >.
                  </div>
                </div>

                <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                  <button
                    ref="kt_login_signup_submit"
                    tabindex="9"
                    class="btn btn-primary loginbutton"
                  >
                    Create Account
                  </button>
                  <div class="loginFooteOuter text-center">
                    <span class="loginFooter"
                      >Have an account?
                      <a
                        id="kt_login_signup"
                        class="text-primary"
                        tabindex="10"
                        style="cursor: pointer"
                        @click="showForm('signin')"
                        >Login</a
                      ></span
                    >
                  </div>
                </div>
              </form>
            </div>
          </transition>

          <!--end::Signup-->
          <!--begin::Forgot-->
          <transition name="slide-up" mode="out-in" @after-enter="onAfterEnter">
            <div
              v-if="state === 'forgot'"
              key="forgot"
              class="login-form login-forgot"
            >
              <!--begin::Form-->
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_forgot_form"
                @submit.prevent="submitForgotPasswordForm"
              >
                <div class="">
                  <img
                    class="mb-6"
                    src="/media/logos/Main_Logo.png"
                    height="50px;"
                  />
                  <!-- <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                  <img src="/media/logos/logo.png" height="55px;" />&nbsp;&nbsp;Refine My Data<br /><br />
                </h3> -->
                  <div class="loginheader">Reset your Password</div>
                  <p class="loginheaderbelow">
                    <!-- Enter your email to reset your password -->
                    Please enter your email to reset your password. If there is
                    an account with this email address, we will send recovery
                    instructions to it.
                  </p>
                </div>
                <div class="form-group">
                  <input
                    class="form-control form-control-solid"
                    type="text"
                    ref="resetemail"
                    placeholder="Email"
                    name="email"
                    tabindex="1"
                    autocomplete="off"
                    v-model="forgotform.email"
                  />
                  <inline-svg
                    width="15"
                    height="15"
                    src="media/svg/icons/General/mail-icon.svg"
                    class="mailicon"
                  />
                  <span
                    v-if="forgotPasswordFormErrors.email"
                    style="color: red"
                    >{{ forgotPasswordFormErrors.email }}</span
                  >
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0">
                  <button
                    ref="kt_login_forgot_submit"
                    tabindex="2"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    ref="kt_login_forgot_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
            <!--end::Forgot-->
          </transition>
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <!--
          <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Terms</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Plans</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contact Us</a
          >
          -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <!-- <div
        class="d-flex flex-column flex-fill"
        style="background-color: olive"
      ></div> -->

      <!--begin::Aside-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
// import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
// import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
// import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

// import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  FORGOT,
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
// import { required, email } from "vuelidate/lib/validators";

export default {
  name: "login-1",
  data() {
    return {
      emailError: "",
      steps: {},
      step: 1,
      state: "signin",
      showpassword: false,
      showregpassword: false,
      showregconfirmpassword: false,
      // Remove this dummy login info
      errors: {
        email: "",
        password: "",
      },
      signinFormErrors: {
        email: "",
        password: "",
      },
      signupFormErrors: {
        name: "",
        surname: "",
        company: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
      },
      forgotPasswordFormErrors: {
        email: "",
      },
      form: {
        email: "",
        password: "",
        remember: false,
      },
      registerform: {
        name: "",
        surname: "",
        company: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
      },
      forgotform: {
        email: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
  },

  mounted() {
    const loginData = this.$cookies.get("credentials");
    if (loginData) {
      this.form.email = loginData.email;
      this.form.remember = loginData.remember;
    }

    // this.fv1 = formValidation(signup_form, {
    //   fields: {
    //     fullname: {
    //       validators: {
    //         notEmpty: {
    //           message: "Full name is required.",
    //         },
    //       },
    //     },
    //     surname: {
    //       validators: {
    //         notEmpty: {
    //           message: "Surname is required.",
    //         },
    //       },
    //     },
    //     company: {
    //       validators: {
    //         notEmpty: {
    //           message: "Company name is required",
    //         },
    //       },
    //     },
    //     email: {
    //       validators: {
    //         notEmpty: {
    //           message: "Email is required",
    //         },
    //         SignupEmailAddress: {
    //           message: "The value is not a valid email address",
    //         },
    //       },
    //     },
    //     cemail: {
    //       validators: {
    //         notEmpty: {
    //           message: "Confirm Email is required",
    //         },
    //         identical: {
    //           compare: function () {
    //             return signup_form.querySelector('[name="email"]').value;
    //           },
    //           message: "The email and its confirm email are not the same",
    //         },
    //       },
    //     },
    //     password: {
    //       validators: {
    //         notEmpty: {
    //           message: "Password is required",
    //         },
    //         checkPassword1: {
    //           message:
    //             "The password is too weak. It needs to be minimum of 10 characters and contain: one special character, one number and one upper case character.",
    //         },
    //       },
    //     },
    //     cpassword: {
    //       validators: {
    //         notEmpty: {
    //           message: "Confirm password is required",
    //         },
    //         identical: {
    //           compare: function () {
    //             return signup_form.querySelector('[name="password"]').value;
    //           },
    //           message: "The password and its confirm are not the same",
    //         },
    //       },
    //     },
    //     agree: {
    //       validators: {
    //         notEmpty: {
    //           message: "You should agree terms and conditions",
    //         },
    //       },
    //     },
    //   },
    //   plugins: {
    //     trigger: new Trigger(),
    //     submitButton: new SubmitButton(),
    //     bootstrap: new Bootstrap(),
    //   },
    // })
    //   .registerValidator("checkPassword1", strongPassword)
    //   .registerValidator("SignupEmailAddress", validateEmail);

    // this.fv2 = formValidation(forgot_form, {
    //   fields: {
    //     email: {
    //       validators: {
    //         notEmpty: {
    //           message: "Password is required",
    //         },
    //         forgotEmailAddress: {
    //           message: "The value is not a valid email address",
    //         },
    //       },
    //     },
    //   },
    //   plugins: {
    //     trigger: new Trigger(),
    //     submitButton: new SubmitButton(),
    //     bootstrap: new Bootstrap(),
    //   },
    // }).registerValidator("forgotEmailAddress", validateEmail);

    // this.fv.on("core.form.valid", this.handleFormValid);
    // this.fv.on("core.form.invalid", this.handleFormInvalid);

    // this.fv1.on("core.form.valid", this.handleSignUpValid);
    // this.fv1.on("core.form.invalid", this.handleSignUpInvalid);

    // this.fv2.on("core.form.valid", this.handleForgotValid);
    // this.fv2.on("core.form.invalid", this.handleForgotInvalid);

    // this.fv.on("core.form.valid", () => {
    //   console.log("Form is valid and submitted");

    //   var email = this.form.email;
    //   var password = this.form.password;
    //   var remember = this.form.remember;

    //   // clear existing errors
    //   this.$store.dispatch(LOGOUT);

    //   // set spinner to submit button
    //   const submitButton = this.$refs["kt_login_signin_submit"];
    //   // submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    //   submitButton.classList.add();

    //   Swal.fire({
    //     title: "Please Wait",
    //     allowOutsideClick: false,
    //     onOpen: () => {
    //       Swal.showLoading();
    //     },
    //   });

    //   this.$store
    //     .dispatch(LOGIN, { email, password, remember })
    //     // go to which page after successfully login
    //     .then(() => this.$router.push({ name: "dashboard" }))
    //     .catch((error) => {
    //       if (error.response && error.response.status === 429) {
    //         this.$bvToast.toast(
    //           "Too many login attempts, please try again later.",
    //           {
    //             title: "Login Error",
    //             variant: "danger",
    //             solid: true,
    //           }
    //         );
    //       } else {
    //         this.$bvToast.toast("Invalid Login", {
    //           title: "Login",
    //           variant: "danger",
    //           solid: true,
    //         });
    //       }
    //     });

    //   submitButton.classList.remove(
    //     "spinner",
    //     "spinner-light",
    //     "spinner-right"
    //   );
    // });

    // this.fv.on("core.form.invalid", () => {
    //   Swal.fire({
    //     title: "",
    //     text: "Incorrect Details, please try again",
    //     icon: "error",
    //     confirmButtonClass: "btn btn-secondary",
    //     heightAuto: false,
    //   });
    //   this.form.password = "";
    //   this.errors.password = "";
    //   this.errors.email = "";
    // });

    // this.fv1.on("core.form.valid", () => {
    //   // clear existing errors
    //   this.$store.dispatch(LOGOUT);

    //   // set spinner to submit button
    //   const submitButton = this.$refs["kt_login_signup_submit"];
    //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");

    //   // dummy delay
    //   setTimeout(() => {
    //     // send register request
    //     this.$store
    //       .dispatch(REGISTER, this.registerform)
    //       .then(() => this.$router.push({ name: "dashboard" }))
    //       .catch(() => {
    //         var jsonData = JSON.parse(this.errors);
    //         Object.keys(jsonData).forEach((key) => {
    //           this.$bvToast.toast(jsonData[key], {
    //             title: "Registration",
    //             variant: "danger",
    //             solid: true,
    //           });
    //         });
    //       });

    //     submitButton.classList.remove(
    //       "spinner",
    //       "spinner-light",
    //       "spinner-right"
    //     );
    //   }, 2000);
    // });

    // this.fv1.on("core.form.invalid", () => {
    //   Swal.fire({
    //     title: "",
    //     text: "Incorrect Details, please try again!",
    //     icon: "error",
    //     confirmButtonClass: "btn btn-secondary",
    //     heightAuto: false,
    //   });
    // });
    // //
    // // if(this.registerform.email && this.registerform.email_confirmation ){

    // // }

    // this.fv2.on("core.form.valid", () => {
    //   this.$store.dispatch(LOGOUT);
    //   var email = this.forgotform.email;

    //   const submitButton = this.$refs["kt_login_forgot_submit"];
    //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");

    //   // dummy delay
    //   // setTimeout(() => {
    //   // send register request
    //   this.$store
    //     .dispatch(FORGOT, { email })
    //     .then(() =>
    //       Swal.fire({
    //         title: "Forgot Password",
    //         text: "A confirmation email has been sent to the new email address. Within the next 5 minutes please confirm and your account email will be changed",
    //         showConfirmButton: true,
    //         confirmButtonText: "Ok",
    //         timer: 10000,
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           this.showForm("signin");
    //         }
    //       })
    //     )
    //     .catch(() => {
    //       Swal.fire({
    //         title: "Forgot Password",
    //         text: "Check email address or error Occured, try again",
    //         showConfirmButton: true,
    //         confirmButtonText: "Ok",
    //         timer: 5000,
    //       });
    //     });

    //   this.forgotform.email = "";

    //   submitButton.classList.remove(
    //     "spinner",
    //     "spinner-light",
    //     "spinner-right"
    //   );
    //   // }, 2000);
    // });

    // this.fv2.on("core.form.invalid", () => {
    //   Swal.fire({
    //     title: "",
    //     text: "Please, provide correct email data!",
    //     icon: "error",
    //     confirmButtonClass: "btn btn-secondary",
    //     heightAuto: false,
    //   });
    // });
    //

    if (this.$route.name == "register") {
      this.showForm("signup");
    }
  },
  methods: {
    //validate email format
    validateEmail(email) {
      const reg = /^[\w+]+([.-]?[\w+]+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return reg.test(email);
    },

    // Custom password validator
    strongPassword(password) {
      // Check if password meets the following criteria:
      // - Minimum of 10 characters
      // - Contains at least one uppercase letter
      // - Contains at least one number
      // - Contains at least one special character
      if (
        password.length >= 10 &&
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password) &&
        /[\W_]/.test(password)
      ) {
        return true;
      }
      return false;
    },
    submitForm() {
      // Clear previous errors
      this.signinFormErrors = {
        email: "",
        password: "",
      };

      // Basic validation for email and password fields
      let isValid = true;

      // Validate email
      if (!this.form.email) {
        this.signinFormErrors.email = "Email is required";
        isValid = false;
      } else if (!this.validateEmail(this.form.email)) {
        this.signinFormErrors.email = "The value is not a valid email address";
        isValid = false;
      }

      // Validate password
      if (!this.form.password) {
        this.signinFormErrors.password = "Password is required";
        isValid = false;
      } else if (!this.strongPassword(this.form.password)) {
        this.signinFormErrors.password =
          "The password is too weak. It needs to be minimum of 10 characters, and contain a special character, a number, and an uppercase letter";
        isValid = false;
      }

      // If validation fails, exit the function
      if (!isValid) {
        this.handleFormInvalid();
        return;
      }

      // If validation passes, call the API
      this.handleFormValid();
    },
    submitSignupForm() {
      // Clear previous errors
      this.signupFormErrors = {
        name: "",
        surname: "",
        company: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
      };

      // Basic validation for signup form fields
      let isValid = true;

      // Validate name
      if (!this.registerform.name) {
        this.$set(this.signupFormErrors, "name", "Forename is required");
        isValid = false;
      }

      // Validate surname
      if (!this.registerform.surname) {
        this.$set(this.signupFormErrors, "surname", "Surname is required");
        isValid = false;
      }

      // Validate company
      if (!this.registerform.company) {
        this.$set(this.signupFormErrors, "company", "Company name is required");
        isValid = false;
      }

      // Validate email
      if (!this.registerform.email) {
        this.$set(this.signupFormErrors, "email", "Email is required");
        isValid = false;
      } else if (!this.validateEmail(this.registerform.email)) {
        this.$set(
          this.signupFormErrors,
          "email",
          "The value is not a valid email address"
        );
        isValid = false;
      }

      if (!this.registerform.email_confirmation) {
        this.$set(
          this.signupFormErrors,
          "email_confirmation",
          "Confirm Email is required"
        );
        isValid = false;
      }

      // Validate email confirmation
      if (this.registerform.email !== this.registerform.email_confirmation) {
        this.$set(
          this.signupFormErrors,
          "email_confirmation",
          "Emails do not match"
        );
        isValid = false;
      }

      // Validate password
      if (!this.registerform.password) {
        this.$set(this.signupFormErrors, "password", "Password is required");
        isValid = false;
      } else if (!this.strongPassword(this.registerform.password)) {
        this.$set(
          this.signupFormErrors,
          "password",
          "The password is too weak. It needs to be minimum of 10 characters, and contain a special character, a number, and an uppercase letter"
        );
        isValid = false;
      }

      // Validate password confirmation
      if (
        this.registerform.password !== this.registerform.password_confirmation
      ) {
        this.$set(
          this.signupFormErrors,
          "password_confirmation",
          "Passwords do not match"
        );
        isValid = false;
      }

      // If form is not valid, return early
      if (!isValid) {
        return;
      }

      // If validation passes, proceed to submit form
      this.handleSignUpValid();
    },
    submitForgotPasswordForm() {
      // Clear previous errors
      this.forgotPasswordFormErrors = {
        email: "",
      };

      // Basic validation for the email field
      let isValid = true;

      // Validate email
      if (!this.forgotform.email) {
        this.$set(this.forgotPasswordFormErrors, "email", "Email is required");
        isValid = false;
      } else if (!this.validateEmail(this.forgotform.email)) {
        this.$set(
          this.forgotPasswordFormErrors,
          "email",
          "The value is not a valid email address"
        );
        isValid = false;
      }

      // If form is not valid, return early
      if (!isValid) {
        return;
      }

      // If validation passes, proceed to submit form
      this.handleForgotValid();
    },
    handleFormValid() {
      var email = this.form.email;
      var password = this.form.password;
      var remember = this.form.remember;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      // const submitButton = this.$refs["kt_login_signin_submit"];
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      this.$store
        .dispatch(LOGIN, { email, password, remember })
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch((error) => {
          console.warn("login api catch error ", error);
          if (error === "invalid_credentials") {
            this.signinFormErrors.email =
              "Incorrect email/password, please try again";
            Swal.fire({
              title: "",
              text: "Incorrect email/password, please try again",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
          if (error && error.status === 429) {
            this.$bvToast.toast(
              "Too many login attempts, please try again later.",
              {
                title: "Login Error",
                variant: "danger",
                solid: true,
              }
            );
          } else {
            this.$bvToast.toast("Invalid Login", {
              title: "Login",
              variant: "danger",
              solid: true,
            });
          }
        });

      // submitButton.classList.remove(
      //   "spinner",
      //   "spinner-light",
      //   "spinner-right"
      // );
    },
    handleFormInvalid() {
      this.form.password = "";
    },
    handleSignUpValid() {
      this.$store.dispatch(LOGOUT);

      this.$store
        .dispatch(REGISTER, this.registerform)
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch((error) => {
          let parsedError = error;
          if (typeof error === "string") {
            try {
              parsedError = JSON.parse(error);
            } catch (e) {
              console.error("Error parsing the JSON string:", e);
            }
          }
          if (parsedError && parsedError.email) {
            this.signupFormErrors.email = parsedError.email[0];
            Swal.fire({
              title: "",
              text: parsedError.email[0],
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
          this.$bvToast.toast("Invalid Details", {
            title: "Registration",
            variant: "danger",
            solid: true,
          });
        });
    },
    handleSignUpInvalid() {
      Swal.fire({
        title: "",
        text: "Incorrect Details, please try again!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    },
    handleForgotValid() {
      this.$store.dispatch(LOGOUT);
      var email = this.forgotform.email;

      // const submitButton = this.$refs["kt_login_forgot_submit"];
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(FORGOT, { email })
        .then(() =>
          Swal.fire({
            title: "Forgot Password",
            text: "A confirmation email has been sent to the new email address. Within the next 5 minutes please confirm and your account email will be changed",
            showConfirmButton: true,
            confirmButtonText: "Ok",
            timer: 10000,
          }).then((result) => {
            if (result.isConfirmed) {
              this.showForm("signin");
            }
          })
        )
        .catch(() => {
          Swal.fire({
            title: "Forgot Password",
            text: "Check email address or error Occured, try again",
            showConfirmButton: true,
            confirmButtonText: "Ok",
            timer: 5000,
          });
        });

      this.forgotform.email = "";
    },
    handleForgotInvalid() {
      Swal.fire({
        title: "",
        text: "Please, provide correct email data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    },
    capitalize(value) {
      if (!value) return "";
      return value
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    prev() {
      this.step--;
    },
    validatemessage() {
      if (this.registerform.email != this.registerform.email_confirmation) {
        this.emailError = "The email and its confirm email are not the same";
      } else {
        this.emailError = "";
      }
    },
    next() {
      this.step++;
    },

    watch: {},
    showForm(form) {
      this.state = form;
      // var form_name = "kt_login_" + form + "_form";
      // KTUtil.animateClass(
      //   KTUtil.getById(form_name),
      //   "animate__animated animate__backInUp"
      // );
      setTimeout(() => {
        if (this.state === "signin") {
          this.signinFormErrors.email = "";
          this.signinFormErrors.password = "";
          // Focus on email input field for sign-in form
          this.$refs.email.focus();
        } else if (this.state === "signup") {
          this.signupFormErrors = {
            name: "",
            surname: "",
            company: "",
            email: "",
            email_confirmation: "",
            password: "",
            password_confirmation: "",
          };
          // Focus on forename input field for sign-up form
          this.$refs.fullname.focus();
        } else if (this.state === "forgot") {
          // Focus on email input field for forgot password form
          this.$refs.resetemail.focus();
        }
      }, 300); // Add a short delay (100ms) to ensure the DOM has updated completely
      // });
    },
    onAfterEnter() {
      // Focus the appropriate input after the transition completes
      if (this.state === "signin") {
        this.$refs.email.focus(); // Focus on email for sign-in
      } else if (this.state === "signup") {
        this.$refs.fullname.focus(); // Focus on forename for sign-up
      } else if (this.state === "forgot") {
        this.$refs.resetemail.focus(); // Focus on email for forgot password
      }
    },
    switchVisibility(flag) {
      return flag ? "text" : "password";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins");

#kt_login {
  font-family: "Poppins", sans-serif;
}

.carousel-caption {
  position: relative !important;
  left: 0 !important;
  color: #000000;
  padding-top: 5%;
}

.carousel-indicators li {
  background-color: rgb(47, 166, 115);
  color: #a3a3aa;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-left: 20px;
}

.loginheader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #1c1f37;
}

.loginheaderbelow {
  width: 459px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #1c1f37;
}

.logoText {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #ff771b;
}

.form-group label {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  letter-spacing: -0.408px;

  color: #1c1f37;
}

.form-view .editicon {
  margin-left: 421px;
  position: absolute;
  margin-top: -30px;
  font-size: 15px;
}

.form-control.form-control-solid {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  width: 450px;
  height: 50px;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border-radius: 15px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  border: 2px solid #bfc1d4;
  padding-left: 39px;
  color: #6f7392;
}

.form-control.input-control-solid {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  /* width: 459px; */
  height: 50px;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
  background: #ffffff;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border-radius: 15px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  border: 2px solid #bfc1d4;
  /* padding-left: 39px; */
  color: #6f7392;
}

.eyeicon {
  position: absolute;
  margin-left: 414px;
  margin-top: -36px;
}

.loginbutton {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;

  color: #ffffff;
  width: 450px;
  height: 50px;
  border-radius: 10px;
}

.form-check-label {
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 147% */
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;

  color: #1c1f37;
}

.form-check-input {
  font-size: 20px;
}

.forgot {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 147% */
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;

  color: #6f7392;
}

.reset {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 147% */
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
}

/* new vector */
.step {
  height: 8px;
  width: 217px;
  background: #d2d4d6;
  margin-left: 13px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.step-active {
  height: 8px;
  width: 217px;
  background-color: #5fb689;
}

.profileicon {
  position: absolute;
  margin-top: -32px;
  margin-left: 14px;
}

.mailicon {
  position: absolute;
  margin-top: -32px;
  margin-left: 14px;
}

.lockicon {
  position: absolute;
  margin-top: -35px;
  margin-left: 14px;
}

.loginFooter {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 147% */
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;

  color: #1c1f37;
}

.loginFooteOuter {
  width: 450px;
  margin-top: 25px;
  border: 1px solid rgb(220, 215, 215);
  padding: 10px 10px;
  border-radius: 20px;
}

input[type="checkbox"]:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 2px #69b3ff; /* Add a custom blue border */
  border: 1px solid #69b3ff; /* Optional: Add a more visible border */
}

/* Define the slide-up transition without zoom */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.6s ease-out; /* Duration and easing for smoothness */
  /* Ensure no scaling */
  transform: scale(1);
}

.slide-up-enter,
.slide-up-leave-to /* .slide-up-leave-active in <2.1.8 */ {
  transform: translateY(100%) scale(1); /* Start off-screen, at the bottom, no scaling */
}

.slide-up-enter-to,
.slide-up-leave {
  transform: translateY(0) scale(1); /* End at the original position, no scaling */
}
* {
  transform: scale(1);
}
</style>
